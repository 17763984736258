import { power_cmd} from "@/mqtt/cmdBean";
import MqttClientM from "@/mqtt/mqtt";
import { topic } from "@/utils/mqttUtil";
export function powerON(robotId: string) {
  const data = {
      'cmd': power_cmd.powerON,
      'value': ''
    }
    MqttClientM.getInstance().publishMessage(topic.power_cmd + robotId,  JSON.stringify(data))
}

export function powerOff(robotId: string) {
    const data = {
        'cmd': power_cmd.powerOFF,
       'value': ''
    }
     MqttClientM.getInstance().publishMessage(topic.power_cmd + robotId,  JSON.stringify(data))
}