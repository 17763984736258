import { StartTaskBean , cmd, TaskStateBean, TaskListBean} from "@/mqtt/cmdBean";
import MqttClientM from "@/mqtt/mqtt";
import { topic, cid } from "@/utils/mqttUtil";
export function startTask(userId: number, map_name: string, task_name: string, robotId: string) {
    const startTask: StartTaskBean = {
        op: "call_service",
        service: "/start_task",
        cid: cid.start + userId,
        args: {
          map_name: map_name,
          task_name: task_name,
        }
      }
      MqttClientM.getInstance().publishMessage(topic.cmdTopic + robotId,  JSON.stringify(startTask))
}

export function cancelTask(userId: number, robotId: string) {
    const taskStateBean: TaskStateBean = {
            op: "call_service",
            service: "/start_task/set_task_state",
            cid: cid.cancel + userId,
            args: {
                cmd: cmd.cancel
            }
      }
      MqttClientM.getInstance().publishMessage(topic.cmdTopic + robotId,  JSON.stringify(taskStateBean))
}
export function pauseTask (userId: number, robotId: string) {
    const taskStateBean: TaskStateBean = {
            op: "call_service",
            service: "/start_task/set_task_state",
            cid: cid.pause + userId,
            args: {
                cmd: cmd.pause,
            }
      }
      MqttClientM.getInstance().publishMessage(topic.cmdTopic + robotId,  JSON.stringify(taskStateBean))
}
export function resumeTask(userId: number, robotId: string) {
    const taskStateBean: TaskStateBean = {
            op: "call_service",
            service: "/start_task/set_task_state",
            cid: cid.resume + userId,
            args: {
                cmd: cmd.resume
            }
      }
      MqttClientM.getInstance().publishMessage(topic.cmdTopic + robotId,  JSON.stringify(taskStateBean))
}
export function startAnonTask(sub_task_list: [], map_name: string, robotId: string, userId: number) {
    const tasklistBean: TaskListBean = {
        op:"call_service",
        service: "/start_anon_task",
        cid: cid.anonTask + userId,
        args:{
            map_name: map_name,
            sub_task_list: sub_task_list
        }
      }
      MqttClientM.getInstance().publishMessage(topic.cmdTopic + robotId,  JSON.stringify(tasklistBean))
}