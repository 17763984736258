/**
 * 开始任务的结构体
 */
 export interface StartTaskBean {
    op: "call_service";
    service: "/start_task";
    cid: string;
    args: {
      map_name: string,
      task_name: string,
    }
}
const power_cmd = {
  powerON: "power_on", 
  powerOFF: "power_off", 
  // upgrade_appupgrade_app, upgrade_firmware
}
const cmd = {
  cancel: "task_cancel",
  pause: "task_pause",
  resume: "task_resume"
};
//取消，暂停，恢复任务结构体
export interface TaskStateBean {
  op: "call_service";
  service: "/start_task/set_task_state";
  cid: string;
  args: {
    cmd: string
  }
}
export interface TaskListBean {
  op:"call_service",
  service: "/start_anon_task",
  cid: string,
  args:{
      map_name: string,
      sub_task_list: [
          // {
          //     "arc_segment_list": [],
          //     "clean_mode": 0,
          //     "index": 0,
          //     "pose_list": [
          //     {
          //         "theta": -1.5671257972717285,
          //         "x": 127.60959,
          //         "y": 67.51228
          //     }
          //     ],
          //     "repeat_time": 1,
          //     "task_mode": 3
          // }
      ]
  }
}
export {
  cmd, power_cmd
};